// App.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import firebaseConfig from './firebaseConfig';
import logo from './svg/swap-vertical-circle-svgrepo-com.svg';
import { isMobile } from 'react-device-detect';

import BitcoinPrice from './BitcoinPrice';
import Ethereum from './Ethereum';
import Bnb from './Bnb';
import Xrp from './Xrp';
import Cardano from './Cardano';
import Doge from './Doge';
import Solana from './Solana';
import Tron from './Tron';
 // Import common CSS file for styling
import WifPrice from './WifPrice';
import Iotx from './Iotx';
import Wld from './Wld';
import Grt from './Grt';
import Cvx from './Cvx';
import Stg from './Stg';
import Fet from './Fet';
import Agix from './Agix';
import Ocean from './Ocean';
import Ckb from './Ckb';
import Stx from './Stx';
import Blur from './Blur';
import Hbar from './Hbar';
import Jasmy from './Jasmy';
import One from './One';
import Magic from './Magic';
import Gmx from './Gmx';
import Ar from './Ar';
import Ksm from './Ksm';
import Cyber from './Cyber';
import Rndr from './Rndr';
import Ssv from './Ssv';
import Fil from './Fil';
import Rvn from './Rvn';
import Loom from './Loom';
import Ens from './Ens';
import Op from './Op';
import Matic from './Matic';
import Ldo from './Ldo';
import Sats from './Sats';
import Fxs from './Fxs';
import Hot from './Hot';
import Ftm from './Ftm';
import Icp from './Icp';
import Near from './Near';
import Bigtime from './Bigtime';
import Apt from './Apt';
import Imx from './Imx';
import Astr from './Astr';
import Cfx from './Cfx';
import Mkr from './Mkr';
import Ordi from './Ordi';

const App = () => {
  const [exchangeRates, setExchangeRates] = useState({
    EUR: 0,
    JPY: 0,
    INR: 0,
    RUB: 0,
    GBP: 0
  });
  const [selectedCrypto, setSelectedCrypto] = useState('bitcoin'); // Set default value to 'bitcoin'

  if (isMobile) {
    import('./App.mobile.css');
  }
  else{
    import( './App.css');
  }

  useEffect(() => {
    // Fetch exchange rates here
  }, []);

  const handleCryptoChange = (crypto) => {
    setSelectedCrypto(crypto);
  };

  const renderCryptoComponent = () => {
    switch (selectedCrypto) {
      case 'bitcoin':
        return exchangeRates ? <BitcoinPrice exchangeRates={exchangeRates} /> : null;
      case 'ethereum':
        return exchangeRates ? <Ethereum exchangeRates={exchangeRates} /> : null;
      case 'xrp':
        return exchangeRates ? <Xrp exchangeRates={exchangeRates} /> : null;
      case 'cardano':
        return exchangeRates ? <Cardano exchangeRates={exchangeRates} /> : null;
      case 'doge':
        return exchangeRates ? <Doge exchangeRates={exchangeRates} /> : null;
      case 'solana':
        return exchangeRates ? <Solana exchangeRates={exchangeRates} /> : null;
      case 'tron':
        return exchangeRates ? <Tron exchangeRates={exchangeRates} /> : null;
      case 'bnb':
        return exchangeRates ? <Bnb exchangeRates={exchangeRates} /> : null;
        case 'wif':
          return exchangeRates ? <WifPrice exchangeRates={exchangeRates} /> : null;
          case 'iotx':
            return exchangeRates ? <Iotx exchangeRates={exchangeRates} /> : null;
            case 'wld':
              return exchangeRates ? <Wld exchangeRates={exchangeRates} /> : null;

              case 'grt':
                return exchangeRates ? <Grt exchangeRates={exchangeRates} /> : null;


              case 'cvx':
                return exchangeRates ? <Cvx exchangeRates={exchangeRates} /> : null;

                case 'stg':
                  return exchangeRates ? <Stg exchangeRates={exchangeRates} /> : null;

                  case 'fet':
                    return exchangeRates ? <Fet exchangeRates={exchangeRates} /> : null;
                    case 'agix':
                      return exchangeRates ? <Agix exchangeRates={exchangeRates} /> : null;
                      case 'ocean':
                        return exchangeRates ? <Ocean exchangeRates={exchangeRates} /> : null;
                        case 'ckb':
                          return exchangeRates ? <Ckb exchangeRates={exchangeRates} /> : null;
                          case 'stx':
                            return exchangeRates ? <Stx exchangeRates={exchangeRates} /> : null;
                            case 'blur':
                              return exchangeRates ? <Blur exchangeRates={exchangeRates} /> : null;
          case 'hbar':
        return exchangeRates ? <Hbar exchangeRates={exchangeRates} /> : null;
        case 'jasmy':
          return exchangeRates ? <Jasmy exchangeRates={exchangeRates} /> : null;
          case 'one':
            return exchangeRates ? <One exchangeRates={exchangeRates} /> : null;
            case 'magic':
              return exchangeRates ? <Magic exchangeRates={exchangeRates} /> : null;
              case 'gmx':
                return exchangeRates ? <Gmx exchangeRates={exchangeRates} /> : null;
                case 'ar':
                  return exchangeRates ? <Ar exchangeRates={exchangeRates} /> : null;
                  case 'ksm':
                    return exchangeRates ? <Ksm exchangeRates={exchangeRates} /> : null;
                    case 'cyber':
                      return exchangeRates ? <Cyber exchangeRates={exchangeRates} /> : null;
                      case 'rndr':
                        return exchangeRates ? <Rndr exchangeRates={exchangeRates} /> : null;
                        case 'ssv':
                          return exchangeRates ? <Ssv exchangeRates={exchangeRates} /> : null;
                          case 'fil':
                            return exchangeRates ? <Fil exchangeRates={exchangeRates} /> : null;
                            case 'rvn':
                              return exchangeRates ? <Rvn exchangeRates={exchangeRates} /> : null;
                              case 'loom':
                                return exchangeRates ? <Loom exchangeRates={exchangeRates} /> : null;
                                case 'ens':
                                  return exchangeRates ? <Ens exchangeRates={exchangeRates} /> : null;
                                  case 'op':
                                    return exchangeRates ? <Op exchangeRates={exchangeRates} /> : null;
                                    case 'matic':
                                      return exchangeRates ? <Matic exchangeRates={exchangeRates} /> : null;
                                      case 'ldo':
                                        return exchangeRates ? <Ldo exchangeRates={exchangeRates} /> : null;
                                        case 'sats':
                                          return exchangeRates ? <Sats exchangeRates={exchangeRates} /> : null;
                                          case 'fxs':
                                            return exchangeRates ? <Fxs exchangeRates={exchangeRates} /> : null;
                                            case 'hot':
                                              return exchangeRates ? <Hot exchangeRates={exchangeRates} /> : null;
                                              case 'ftm':
                                                return exchangeRates ? <Ftm exchangeRates={exchangeRates} /> : null;
                                                case 'icp':
                                                  return exchangeRates ? <Icp exchangeRates={exchangeRates} /> : null;
                                                  case 'near':
                                                    return exchangeRates ? <Near exchangeRates={exchangeRates} /> : null;
                                                    case 'bigtime':
                                                      return exchangeRates ? <Bigtime exchangeRates={exchangeRates} /> : null;
                                                      case 'apt':
                                                        return exchangeRates ? <Apt exchangeRates={exchangeRates} /> : null;
                       case 'imx':
                        return exchangeRates ? <Imx exchangeRates={exchangeRates} /> : null;
                        case 'astr':
                          return exchangeRates ? <Astr exchangeRates={exchangeRates} /> : null;
                          case 'cfx':
                            return exchangeRates ? <Cfx exchangeRates={exchangeRates} /> : null;
                            case 'mkr':
                              return exchangeRates ? <Mkr exchangeRates={exchangeRates} /> : null;
                              case 'ordi':
                                return exchangeRates ? <Ordi exchangeRates={exchangeRates} /> : null;
      default:
        return null;
    }
  };

  return (
    <div className={`app-container ${isMobile ? 'mobile' : 'desktop'}`}>
      <div className="header">
        <img src={logo} alt="rbit365 Logo" className="logo" />
        <h2 className="title">r₿it365</h2>
        <p className='bybitDetails'>Get sign up bonuses if you meet the deposit requirements</p>
        <a href="https://bit.ly/486GPM0" target="_blank" rel="noopener noreferrer" className="byBitLink">
          <div className='byBit'>
            <img className='bybitLogo' src="https://btctools.io/static/media/bybit-cta-logo.7f806195.png"></img>
            <div>
              <h3 className='visitBybit'>Visit Bybit</h3>
              <p className='sponsored'>Sponsored</p>
            </div>
          </div>
        </a>
      </div>
      <h3 className="subtitle">Compare prices and find the best crypto trading platform to buy and sell crypto</h3>

      <div className="button-container">
        <button className={`crypto-button ${selectedCrypto === 'bitcoin' ? 'selected' : ''}`} onClick={() => handleCryptoChange('bitcoin')}>BTC</button>
        <button className={`crypto-button ${selectedCrypto === 'ethereum' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ethereum')}>ETH</button>
        <button className={`crypto-button ${selectedCrypto === 'bnb' ? 'selected' : ''}`} onClick={() => handleCryptoChange('bnb')}>BNB</button>
        <button className={`crypto-button ${selectedCrypto === 'xrp' ? 'selected' : ''}`} onClick={() => handleCryptoChange('xrp')}>XRP</button>
        <button className={`crypto-button ${selectedCrypto === 'cardano' ? 'selected' : ''}`} onClick={() => handleCryptoChange('cardano')}>ADA</button>
        <button className={`crypto-button ${selectedCrypto === 'doge' ? 'selected' : ''}`} onClick={() => handleCryptoChange('doge')}>DOGE</button>
        <button className={`crypto-button ${selectedCrypto === 'solana' ? 'selected' : ''}`} onClick={() => handleCryptoChange('solana')}>SOL</button>
        <button className={`crypto-button ${selectedCrypto === 'tron' ? 'selected' : ''}`} onClick={() => handleCryptoChange('tron')}>TRX</button>
        <button className={`crypto-button ${selectedCrypto === 'wif' ? 'selected' : ''}`} onClick={() => handleCryptoChange('wif')}>WIF</button>
        <button className={`crypto-button ${selectedCrypto === 'iotx' ? 'selected' : ''}`} onClick={() => handleCryptoChange('iotx')}>IOTX</button>
        <button className={`crypto-button ${selectedCrypto === 'wld' ? 'selected' : ''}`} onClick={() => handleCryptoChange('wld')}>WLD</button>
        <button className={`crypto-button ${selectedCrypto === 'grt' ? 'selected' : ''}`} onClick={() => handleCryptoChange('grt')}>GRT</button>
        <button className={`crypto-button ${selectedCrypto === 'cvx' ? 'selected' : ''}`} onClick={() => handleCryptoChange('cvx')}>CVX</button>
        <button className={`crypto-button ${selectedCrypto === 'stg' ? 'selected' : ''}`} onClick={() => handleCryptoChange('stg')}>STG</button>
        <button className={`crypto-button ${selectedCrypto === 'fet' ? 'selected' : ''}`} onClick={() => handleCryptoChange('fet')}>FET</button>
        <button className={`crypto-button ${selectedCrypto === 'agix' ? 'selected' : ''}`} onClick={() => handleCryptoChange('agix')}>AGIX</button>
        <button className={`crypto-button ${selectedCrypto === 'ocean' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ocean')}>OCEAN</button>
        <button className={`crypto-button ${selectedCrypto === 'ckb' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ckb')}>CKB</button>
        <button className={`crypto-button ${selectedCrypto === 'stx' ? 'selected' : ''}`} onClick={() => handleCryptoChange('stx')}>STX</button>
        <button className={`crypto-button ${selectedCrypto === 'blur' ? 'selected' : ''}`} onClick={() => handleCryptoChange('blur')}>BLUR</button>
        <button className={`crypto-button ${selectedCrypto === 'hbar' ? 'selected' : ''}`} onClick={() => handleCryptoChange('hbar')}>HBAR</button>
        <button className={`crypto-button ${selectedCrypto === 'jasmy' ? 'selected' : ''}`} onClick={() => handleCryptoChange('jasmy')}>JASMY</button>
        <button className={`crypto-button ${selectedCrypto === 'one' ? 'selected' : ''}`} onClick={() => handleCryptoChange('one')}>ONE</button>
        <button className={`crypto-button ${selectedCrypto === 'magic' ? 'selected' : ''}`} onClick={() => handleCryptoChange('magic')}>MAGIC</button>
        <button className={`crypto-button ${selectedCrypto === 'gmx' ? 'selected' : ''}`} onClick={() => handleCryptoChange('gmx')}>GMX</button>
        <button className={`crypto-button ${selectedCrypto === 'ar' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ar')}>AR</button>
        <button className={`crypto-button ${selectedCrypto === 'ksm' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ksm')}>KSM</button>
        <button className={`crypto-button ${selectedCrypto === 'cyber' ? 'selected' : ''}`} onClick={() => handleCryptoChange('cyber')}>CYBER</button>
        <button className={`crypto-button ${selectedCrypto === 'rndr' ? 'selected' : ''}`} onClick={() => handleCryptoChange('rndr')}>RNDR</button>
        <button className={`crypto-button ${selectedCrypto === 'ssv' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ssv')}>SSV</button>
        <button className={`crypto-button ${selectedCrypto === 'fil' ? 'selected' : ''}`} onClick={() => handleCryptoChange('fil')}>FIL</button>
        <button className={`crypto-button ${selectedCrypto === 'rvn' ? 'selected' : ''}`} onClick={() => handleCryptoChange('rvn')}>RVN</button>
        <button className={`crypto-button ${selectedCrypto === 'loom' ? 'selected' : ''}`} onClick={() => handleCryptoChange('loom')}>LOOM</button>
        <button className={`crypto-button ${selectedCrypto === 'ens' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ens')}>ENS</button>
        <button className={`crypto-button ${selectedCrypto === 'op' ? 'selected' : ''}`} onClick={() => handleCryptoChange('op')}>OP</button>
        <button className={`crypto-button ${selectedCrypto === 'matic' ? 'selected' : ''}`} onClick={() => handleCryptoChange('matic')}>MATIC</button>
        <button className={`crypto-button ${selectedCrypto === 'ldo' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ldo')}>LDO</button>
        <button className={`crypto-button ${selectedCrypto === 'sats' ? 'selected' : ''}`} onClick={() => handleCryptoChange('sats')}>SATS</button>
        <button className={`crypto-button ${selectedCrypto === 'fxs' ? 'selected' : ''}`} onClick={() => handleCryptoChange('fxs')}>FXS</button>
        <button className={`crypto-button ${selectedCrypto === 'hot' ? 'selected' : ''}`} onClick={() => handleCryptoChange('hot')}>HOT</button>
        <button className={`crypto-button ${selectedCrypto === 'ftm' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ftm')}>FTM</button>
        <button className={`crypto-button ${selectedCrypto === 'icp' ? 'selected' : ''}`} onClick={() => handleCryptoChange('icp')}>ICP</button>
        <button className={`crypto-button ${selectedCrypto === 'near' ? 'selected' : ''}`} onClick={() => handleCryptoChange('near')}>NEAR</button>
        <button className={`crypto-button ${selectedCrypto === 'bigtime' ? 'selected' : ''}`} onClick={() => handleCryptoChange('bigtime')}>BIGTIME</button>
        <button className={`crypto-button ${selectedCrypto === 'apt' ? 'selected' : ''}`} onClick={() => handleCryptoChange('apt')}>APT</button>
        <button className={`crypto-button ${selectedCrypto === 'imx' ? 'selected' : ''}`} onClick={() => handleCryptoChange('imx')}>IMX</button>
        <button className={`crypto-button ${selectedCrypto === 'astr' ? 'selected' : ''}`} onClick={() => handleCryptoChange('astr')}>ASTR</button>
        <button className={`crypto-button ${selectedCrypto === 'cfx' ? 'selected' : ''}`} onClick={() => handleCryptoChange('cfx')}>CFX</button>
        <button className={`crypto-button ${selectedCrypto === 'mkr' ? 'selected' : ''}`} onClick={() => handleCryptoChange('mkr')}>MKR</button>
        <button className={`crypto-button ${selectedCrypto === 'ordi' ? 'selected' : ''}`} onClick={() => handleCryptoChange('ordi')}>ORDI</button>
      </div>

      {renderCryptoComponent()}
    </div>
  );
};

export default App;
