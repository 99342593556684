import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ReactComponent as YourSvg } from './svg/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';

const Bnb = (props) => {

  if (isMobile) {
    import('./BitcoinPrice.mobile.css');
  }
  else{
    import( './BitcoinPrice.css');
  }

  const [lastPrice, setLastPrice] = useState(null);
  const [exchangePrices, setExchangePrices] = useState([]);

  const updatePriceColor = (currentPrice, lastPrice) => {
    if (!lastPrice || currentPrice === lastPrice) {
      return 'black';
    } else if (currentPrice > lastPrice) {
      return '#0CCB81';
    } else {
      return 'red';
    }
  };
  const updateExchangePrices = (exchange, price) => {
    setExchangePrices((prevPrices) => {
      // Check if the exchange already exists in the array
      const index = prevPrices.findIndex((item) => item.exchange === exchange);
  
      if (index !== -1) {
        // If the exchange exists, update its price
        const updatedPrices = [...prevPrices];
        updatedPrices[index].price = price;
        return sortExchangePrices(updatedPrices); // Sort the prices and return the sorted array
      } else {
        // If the exchange doesn't exist, add it to the array
        const newPrice = { exchange, price };
        const updatedPrices = [...prevPrices, newPrice];
        return sortExchangePrices(updatedPrices); // Sort the prices and return the sorted array
      }
    });
  };
  
  const sortExchangePrices = (prices) => {
    const loadingPrices = prices.filter((item) => item.price === null || isNaN(item.price));
    const loadedPrices = prices.filter((item) => item.price !== null && !isNaN(item.price));
  
    const sortedPrices = loadedPrices.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  
    return sortedPrices.concat(loadingPrices);
  };
  
  const calculatePriceDifferencePercentage = (currentPrice, lowestPrice) => {
    if (!currentPrice || !lowestPrice) {
      return '';
    }
  
    const diffPercentage = ((currentPrice - lowestPrice) / lowestPrice) * 100;
    return diffPercentage.toFixed(2);
  };
  useEffect(() => {
    let binanceWs = null
    // let coinbaseWs = null
    // let bitfinexWs =null
    // let bitstampWs = null
    // let krakenWs =null
    let bybitWs =null
   // let bitflyerWs = null
    let okxWs = null
    //let geminiWs = null
    let gateioWs = null
    let bitGetWs =null
    let mexcWs = null
    // let coincheckWs = null
    // let cryptodotcomWs =null
    let btcexWs = null
    let xtWs = null
    let phemexWs = null
    let wazirxWs=null
    let p2bWs=null
    let btseWs=null
    //let testWs = null


  
 //testWs = new WebSocket('wss://api.phemex.com/md');

    var exchangeRateJPY;
    var exchangeRateEUR;
    var exchangeRateINR;
    var roundingNumber=2

    
    exchangeRateJPY= props.exchangeRates.JPY
    exchangeRateEUR=props.exchangeRates.EUR
    exchangeRateINR=props.exchangeRates.INR
                      
function connectBinanceWebsocket(){
    binanceWs = new WebSocket('wss://fstream.binance.com/ws/bnbusdt@aggTrade');
     binanceWs.onmessage = (event) => {
        try{
            const priceObject = JSON.parse(event.data);
            const price = parseFloat(priceObject.p).toFixed(roundingNumber);
            
            setLastPrice(price);
            updateExchangePrices('Binance', price);  
        }
        catch(e){

        }
      
        
      };

      binanceWs.onclose = function(e) {
    console.log('Binance websocket is closed', e);
        connectBinanceWebsocket();

  };
  binanceWs.onerror = function(err) {
    console.error('Binance WebSocket encountered error: ', err.message, 'Closing socket');
    try{
        binanceWs.close();
       }
       catch(e){

       }
  };
}
   

// function connectCoinbaseWebsocket(){
//     coinbaseWs = new WebSocket('wss://ws-feed.pro.coinbase.com');
//     coinbaseWs.onopen = () => {
//         coinbaseWs.send(
//           JSON.stringify({
//             type: 'subscribe',
//             product_ids: ['BNB-USD'],
//             channels: ['ticker'],
//           })
//         );
//         console.log("coinbaseWs is opened")
//       };
  
//       coinbaseWs.onmessage = (event) => {
//         const data = JSON.parse(event.data);

//         try{
//             if (data.type === 'ticker') {
//                 const price = parseFloat(data.price).toFixed(roundingNumber);
                
//                 setLastPrice(price);
//                 updateExchangePrices('Coinbase', price);
//               }
//         }catch(e){

//         }
  
       
       
        
//       };


//       coinbaseWs.onclose = function(e) {
//         console.log('Coinbase websocket is closed', e);

//           connectCoinbaseWebsocket();

//       };
//       coinbaseWs.onerror = function(err) {
//         console.error('Coinbase WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             coinbaseWs.close();
//            }
//            catch(e){
    
//            }
//       };
  
// }



// function connectBitfinexWebsocket(){
//     bitfinexWs = new WebSocket('wss://api-pub.bitfinex.com/ws/2');
//     bitfinexWs.onopen = () => {
//         bitfinexWs.send(
//           JSON.stringify({ 
//               event: 'subscribe', 
//               channel: 'trades', 
//               symbol: 'tBNBUSD' 
//             })
//         );
//         console.log("bitfinexWs is opened")
//       };
    
//       bitfinexWs.onmessage = (event) => {
//         try{
//           const data = JSON.parse(event.data);
         
//           setLastPrice(data[2][3]);
//           updateExchangePrices('BitFinex', data[2][3]);
//         }
//         catch(e){
  
//         }
        
       
//       };

//       bitfinexWs.onclose = function(e) {
//         console.log('bitfinexWs websocket is closed', e);

//             connectBitfinexWebsocket();

//       };
//       bitfinexWs.onerror = function(err) {
//         console.error('bitfinexWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             bitfinexWs.close();
//            }
//            catch(e){
    
//            }
//       };
// }





// function connectBitstampWebsocket(){
//     bitstampWs = new WebSocket('wss://ws.bitstamp.net');
//     bitstampWs.onopen = () => {
//         bitstampWs.send(
//           JSON.stringify({
//             event: 'bts:subscribe',
//             data: {
//               channel: 'live_trades_bnbusdt',
//             },
//           })
//         );
//         console.log("bitstampWs is opened")
//       };
  
//       bitstampWs.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         try{
//             if (data.event === 'trade' && data.channel === 'live_trades_bnbusdt') {
//                 const price = parseFloat(data.data.price).toFixed(roundingNumber);
               
//                 setLastPrice(price);
//                 updateExchangePrices("BitStamp", price)
//               }
//         }
//         catch(e){

//         }
  
       
       
//       };

//       bitstampWs.onclose = function(e) {
//         console.log('bitstampWs websocket is closed', e);

//             connectBitstampWebsocket();

//       };
//       bitstampWs.onerror = function(err) {
//         console.error('bitstampWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             bitstampWs.close();
//            }
//            catch(e){
    
//            }
//       };

// }



// function connectKrakenWebsocket(){
//     krakenWs = new WebSocket('wss://ws.kraken.com/public');
//     krakenWs.onopen = () => {
//         krakenWs.send(
//           JSON.stringify({
//             event: 'subscribe',
//             pair: ['BNB/USD'],
//             subscription: {
//               name: 'trade',
//             },
//           })
//         );

//         console.log("krakenWs is opened")
//       };
  
//       krakenWs.onmessage = (event) => {
//         try{
//           const data = JSON.parse(event.data);
            
//           setLastPrice(data[1][0][0]);
//           updateExchangePrices("Kraken", data[1][0][0])
         
//         }
//         catch(e){
  
//         }
//       };

//       krakenWs.onclose = function(e) {
//         console.log('krakenWs websocket is closed', e);

//             connectKrakenWebsocket();

//       };
//       krakenWs.onerror = function(err) {
//         console.error('krakenWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             krakenWs.close();
//            }
//            catch(e){
    
//            }
//       };

// }

 



function bybitWebsocket(){
    
    bybitWs = new WebSocket('wss://stream.bybit.com/v5/public/linear');
    bybitWs.onopen = () => {
        bybitWs.send(
          JSON.stringify({
            "op": "subscribe",
            "args": [
                "publicTrade.BNBUSDT"
            ]
        })

        
        );
        console.log("bybitWs is opened")
      };
  
      bybitWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        //console.log(data)
  
        if (data.topic === 'publicTrade.BNBUSDT') {
            try{
                const price = parseFloat(data.data[0].p).toFixed(roundingNumber);
                
                setLastPrice(price);
                updateExchangePrices("ByBit",price)
            }
            catch(e){

            }
       
        }
       
      };

      bybitWs.onclose = function(e) {
        console.log('bybitWs websocket is closed', e);

            bybitWebsocket();

      };
      bybitWs.onerror = function(err) {
        console.error('bybitWs WebSocket encountered error: ', err.message, 'Closing socket');
        try{
            bybitWs.close();
           }
           catch(e){
    
           }
      };

  
}

  




// function connectBitflyerWebsocket(){
//     bitflyerWs = new WebSocket('wss://ws.lightstream.bitflyer.com/json-rpc');
//     bitflyerWs.onopen = () => {
//         bitflyerWs.send(
//           JSON.stringify({
//             method: 'subscribe',
//             params: {
//               channel: 'lightning_executions_BNB_JPY',
//             },
//           })
//         );
//         console.log("bitflyerWs is opened")
//       };
  
//       bitflyerWs.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         try{
            
//             setLastPrice((data.params.message[0].price/exchangeRateJPY).toFixed(roundingNumber));
//             updateExchangePrices("BitFlyer",(data.params.message[0].price/exchangeRateJPY).toFixed(roundingNumber))
//         }
//         catch(e){

//         }
       
       
//       };   


//       bitflyerWs.onclose = function(e) {
//         console.log('bitflyerWs websocket is closed', e);

//             connectBitflyerWebsocket();

//       };
//       bitflyerWs.onerror = function(err) {
//         console.error('bitflyerWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             bitflyerWs.close();
//            }
//            catch(e){
    
//            }
//       };
// }





function connectOkxwebsocket(){
  okxWs = new WebSocket('wss://ws.okx.com:8443/ws/v5/public');
  okxWs.onopen = () => {
      okxWs.send(
        JSON.stringify({
          "op": "subscribe",
          "args": [
            {
              "channel": "trades",
              "instId": "BNB-USDT",
              "instType":"FUTURES"
            }
          ]
        })
      );
      console.log("okxWs is opened")
    };

    okxWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
      try{
          
          setLastPrice(data.data[0].px);
          updateExchangePrices("OkX",data.data[0].px )
      }
      catch(e){

      }

    
  
    };

    okxWs.onclose = function(e) {
      console.log('okxWs websocket is closed', e);

          connectOkxwebsocket();

    };
    okxWs.onerror = function(err) {
      console.error('okxWs WebSocket encountered error: ', err.message, 'Closing socket');
      okxWs.close();
    };
}




   

  
// function connectGeminiWebsocket(){
//     geminiWs = new WebSocket('wss://api.gemini.com/v1/multimarketdata?symbols=BNBUSD');
//     geminiWs.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         console.log(data)
//         try{
           
//             setLastPrice(data.events[0].price);
//             updateExchangePrices("Gemini", data.events[0].price)
//         }
//         catch(e){

//         }

       
//       };


//       geminiWs.onclose = function(e) {
//         console.log('geminiWs websocket is closed', e);

//             connectGeminiWebsocket();

//       };
//       geminiWs.onerror = function(err) {
//         console.error('geminiWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             geminiWs.close();
//            }
//            catch(e){
    
//            }
//       };
 
// }





function connectGateioWebsocket(){
  gateioWs = new WebSocket('wss://fx-ws.gateio.ws/v4/ws/usdt');
  gateioWs.onopen = () => {
      gateioWs.send(
        JSON.stringify({"time" : Date.now(), "channel" : "futures.trades", "event": "subscribe", "payload" : ["BNB_USDT"]})
      );

      console.log("gateioWs is opened")
    };

    gateioWs.onmessage = (event) => {
      try{
        const data = JSON.parse(event.data);
 
      
       setLastPrice(data.result[0].price)
       updateExchangePrices("Gate.io",data.result[0].price)
      }
      catch(e){
        
      }
     
  
    };

    gateioWs.onclose = function(e) {
      console.log('gateioWs websocket is closed', e);

          connectGateioWebsocket();

    };
    gateioWs.onerror = function(err) {
      console.error('gateioWs WebSocket encountered error: ', err.message, 'Closing socket');
      gateioWs.close();
    };
}


function connectBitGetWebsocket(){

  bitGetWs = new WebSocket('wss://ws.bitget.com/mix/v1/stream');
  bitGetWs.onopen = () => {
      bitGetWs.send(
        JSON.stringify(
          {
            "op":"subscribe",
            "args":[
              {
                "instType":"mc",
                "channel":"trade",
                "instId":"BNBUSDT"
              }
            ]
          })
      );
      console.log("bitGetWs is opened")
    };

    bitGetWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
   
      try{
         
          setLastPrice((data.data[0][1]))
          updateExchangePrices("BitGet",(data.data[0][1]))
      }
      catch(e){

      }

     
      
    };

    bitGetWs.onclose = function(e) {
      console.log('bitGetWs websocket is closed', e);
 
          connectBitGetWebsocket();

    };
    bitGetWs.onerror = function(err) {
      console.error('bitGetWs WebSocket encountered error: ', err.message, 'Closing socket');
      bitGetWs.close();
    };
}






function connectMexcWebsocket(){
  mexcWs = new WebSocket('wss://contract.mexc.com/ws');
  mexcWs.onopen = () => {
      mexcWs.send(
        JSON.stringify(
          {
            "method":"sub.deal",
            "param":{
                "symbol":"BNB_USDT"
            }
        })
      );

      console.log("mexcWs is opened")
    };

    mexcWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
     
      try{
         
          setLastPrice(data.data.p)
          updateExchangePrices("MexC",data.data.p)
      }
      catch(e){

      }

     
      
    };

    mexcWs.onclose = function(e) {
      console.log('mexcWs websocket is closed', e);

          connectMexcWebsocket();

    };
    mexcWs.onerror = function(err) {
      console.error('mexcWs WebSocket encountered error: ', err.message, 'Closing socket');
      mexcWs.close();
    };
}

    

// function connectCoincheckWebsocket(){
//     coincheckWs = new WebSocket('wss://ws-api.coincheck.com/');
//     coincheckWs.onopen = () => {
//         coincheckWs.send(
//           JSON.stringify(
//             {type: "subscribe", channel: "bnb_jpy-trades"})
//         );

//         console.log("coincheckWs is opened")
//       };
  
//       coincheckWs.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         try{
           
//             setLastPrice((data[0][3]/exchangeRateJPY).toFixed(roundingNumber))
//             updateExchangePrices("CoinCheck", (data[0][3]/exchangeRateJPY).toFixed(roundingNumber))
    
//         }
//         catch(e){

//         }

      
        
//       };

//       coincheckWs.onclose = function(e) {
//         console.log('coincheckWs websocket is closed', e);

//             connectCoincheckWebsocket();

//       };
//       coincheckWs.onerror = function(err) {
//         console.error('coincheckWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             coincheckWs.close();
//            }
//            catch(e){
    
//            }
//       };
// }











// function connectBtcexWebsocket(){
//     btcexWs = new WebSocket('wss://api.btcex.com/ws/api/v1');
//     btcexWs.onopen = () => {
//         btcexWs.send(
//           JSON.stringify(
//             {
//                 "jsonrpc":"2.0",
//                 "id":1,
//                 "method":"/public/get_last_trades_by_instrument",
//                 "params":{
//                     "instrument_name":"BNB-USDT-PERPETUAL"
//                 }
//             })
//         );

//         console.log("btcexWs is opened")
//       };
  
//       btcexWs.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         try{
            
//             setLastPrice(data.result.trades[0].price)
//             updateExchangePrices("BitCex", data.result.trades[0].price)
//         }
//         catch(e){

//         }
        
//       };

//       btcexWs.onclose = function(e) {
//         console.log('btcexWs websocket is closed');

//             connectBtcexWebsocket();

//       };
//       btcexWs.onerror = function(err) {
//         console.error('btcexWs WebSocket encountered error: ', err.message, 'Closing socket');
//         try{
//             btcexWs.close();
//            }
//            catch(e){
    
//            }
//       };

// }



     

function connectXTwebsocket(){
    xtWs = new WebSocket('wss://fstream.xt.com/ws/market');
    xtWs.onopen = () => {
        xtWs.send(
          JSON.stringify(
            {
                "method": "subscribe", 
                "params": [
                    "trade@bnb_usdt", 
                ], 
                "id": 1   //call back ID
            })
        );
        console.log("xtWs is opened")
      };
  
      xtWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
            try{
                
                setLastPrice(data.data.p)
                updateExchangePrices("XT.com", data.data.p)
            }
            catch(e){

            }

         
             
        
      };


      xtWs.onclose = function(e) {
        console.log('xtWs websocket is closed');

            connectXTwebsocket();

      };
      xtWs.onerror = function(err) {
        console.error('xtWs WebSocket encountered error: ', err.message, 'Closing socket');
        try{
            xtWs.close();
           }
           catch(e){
    
           }
      };
      
}



function connectPhemexWebsocket(){
    phemexWs = new WebSocket('wss://api.phemex.com/md');
    phemexWs.onopen = () => {
        phemexWs.send(
          JSON.stringify(
            {
                "id": 0,
                "method": "trade.subscribe",
                "params": [
                  "BNBUSD"
                ]
              })
        );
        console.log("phemexWs is opened")
      };
  
      phemexWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
            try{
                
                setLastPrice(data.trades[0][2]/10000)
                updateExchangePrices("Phemex", data.trades[0][2]/10000)

            }
            catch(e){

            }        
        
      };

      phemexWs.onclose = function(e) {
        console.log('phemexWs websocket is closed', );

            connectPhemexWebsocket();
  
      };
      phemexWs.onerror = function(err) {
        console.error('phemexWs WebSocket encountered error: ', err.message, 'Closing socket');
       try{
        phemexWs.close();
       }
       catch(e){

       }
       
      };

}

function connectWazirxWebsocket(){
  wazirxWs = new WebSocket('wss://stream.wazirx.com/stream');
  wazirxWs.onopen = () => {
    wazirxWs.send(
        JSON.stringify(
          {"event":"subscribe","streams":["bnbusdt@trades"]})
      );
      console.log("wazirxWs is opened")
    };

    wazirxWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
   
          try{
           
              
              setLastPrice(data.data.trades[0].p)
              updateExchangePrices("WazirX",data.data.trades[0].p)

          }
          catch(e){

          }        
      
    };

    wazirxWs.onclose = function(e) {
      console.log('phemexWs websocket is closed', );

      connectWazirxWebsocket();

    };
    wazirxWs.onerror = function(err) {
      console.error('phemexWs WebSocket encountered error: ', err.message, 'Closing socket');
      wazirxWs.close();
    };

}



function connectP2BWebsocket(){
  p2bWs = new WebSocket('wss://apiws.p2pb2b.com/');
  p2bWs.onopen = () => {
    p2bWs.send(
        JSON.stringify(
          {"method":"price.subscribe","params":["BNB_USDT"],"id":1})
      );
      console.log("p2bWs is opened")
    };

    p2bWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
   
          try{
            console.log(data.params[1])
              
              setLastPrice((data.params[1]))
              updateExchangePrices("P2B", (data.params[1]))

          }
          catch(e){

          }        
      
    };

    p2bWs.onclose = function(e) {
      console.log('p2bWs websocket is closed', );

      connectP2BWebsocket();

    };
    p2bWs.onerror = function(err) {
      console.error('p2bWs WebSocket encountered error: ', err.message, 'Closing socket');
      p2bWs.close();
    };

}
function connectBTSEWebsocket(){
  btseWs = new WebSocket('wss://ws.btse.com/ws/spot');
  btseWs.onopen = () => {
    btseWs.send(
        JSON.stringify(
          {
            "op": "subscribe",
            "args": [
              "tradeHistoryApi:BNB-USD"
            ]
          })
      );
      console.log("btseWs is opened")
    };

    btseWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
   
          try{
            console.log(data.data[0].price)
              
              setLastPrice(data.data[0].price)
              updateExchangePrices("BTSE", data.data[0].price)

          }
          catch(e){

          }        
      
    };

    btseWs.onclose = function(e) {
      console.log('btseWs websocket is closed', );

      connectBTSEWebsocket();

    };
    btseWs.onerror = function(err) {
      console.error('btseWs WebSocket encountered error: ', err.message, 'Closing socket');
      btseWs.close();
    };

}

connectBinanceWebsocket()
//connectCoinbaseWebsocket()
//connectBitfinexWebsocket()
//connectBitstampWebsocket()
//connectKrakenWebsocket()
bybitWebsocket()
//connectBitflyerWebsocket()
connectOkxwebsocket()
//connectGeminiWebsocket()
connectGateioWebsocket()
connectBitGetWebsocket()
connectMexcWebsocket()
//connectCoincheckWebsocket()
//connectCryptodotcomWebsocket()
connectWazirxWebsocket()
connectP2BWebsocket()
//connectBTSEWebsocket()
//connectHuobiWebsocket()
//connectHuobiGBPWebsocket()
//connectZaifWebsocket()
//connectKorbitWebsocket()
//connectBtcexWebsocket()
connectXTwebsocket()
//connectPhemexWebsocket()







    //   testWs.onopen = () => {
    //     testWs.send(
    //       JSON.stringify(
    //         {
    //             "id": 0,
    //             "method": "trade.subscribe",
    //             "params": [
    //               "BTCUSD"
    //             ]
    //           })
    //     );
    //   };
  
    //   testWs.onmessage = (event) => {
    //     const data = JSON.parse(event.data);
    //         try{
    //             console.log(data.trades[0][2]/10000)

    //         }
    //         catch(e){

    //         }
            
        
        
    //   };


    

    return () => {
      binanceWs.close();
     // coinbaseWs.close();
      // bitfinexWs.close();
      // bitstampWs.close();
      // krakenWs.close();
       bybitWs.close();
       //bitflyerWs.close();
       okxWs.close();
      // geminiWs.close()
       gateioWs.close()
       bitGetWs.close()
       mexcWs.close()
       //coincheckWs.close()
       //cryptodotcomWs.close()
       wazirxWs.close()
       //p2bWs.close()
       //btseWs.close()
       //huobiWs.close()
       //huobiGBPWs.close()
       //zaifWs.close()
      // korbitWs.close()
       //btcexWs.close()
       xtWs.close()
       //phemexWs.close()
       //testWs.close()
    };
  }, []);


  const exchangeWebsites = {
    Binance: {
      url: 'https://bit.ly/44U4Za8',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/e/e8/Binance_Logo.svg',
      type:"Futures",
      symbol:"BNBUSDT"
    },
    Coinbase: {
      url: 'https://www.coinbase.com/',
      logo: 'https://companieslogo.com/img/orig/COIN-a63dbab3.png?t=1648737284',
      symbol:"BNBUSD"
    },
    BitFinex: {
      url: 'https://bit.ly/3XQV4jp',
      logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/bitfinex-logo.webp',
      type:"Trading",
      symbol:"BNBUSD"
    },
    BitStamp: {
      url: 'https://www.bitstamp.net/',
      logo: 'https://cdn1.vectorstock.com/i/1000x1000/56/10/exchange-bitstamp-copy-the-crypto-coins-vector-23285610.jpg',
    },
    Kraken: {
      url: 'https://www.kraken.com/',
      logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/kraken-logo.jpg',
      type:"Futures",
      symbol:"BNBUSD"
    },
    ByBit: {
      url: 'https://bit.ly/3PSQ8IY',
      logo: 'https://btctools.io/static/media/bybit-cta-logo.7f806195.png',
      type:"Futures",
      symbol:"BNBUSDT"
    },
    BitFlyer:{
        url: 'https://bitflyer.com/',
        logo: 'https://seeklogo.com/images/B/bitflyer-logo-C396C604C6-seeklogo.com.png',
    },
    OkX:{
      url: 'https://bit.ly/44JNN7A',
      logo: 'https://altcoinsbox.com/wp-content/uploads/2023/03/okx-logo.jpg',
      type:"Futures",
      symbol:"BNBUSDT"  
  },
    Gemini:{
        url: 'https://www.gemini.com/',
        logo: 'https://seeklogo.com/images/G/gemini-dollar-gusd-logo-4333BE15A8-seeklogo.com.png',  
    },
    "Gate.io":{
      url: 'https://www.gate.io/',
      logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/gate.io-logo.png', 
      type:"Derivatives",
      symbol:"BNBUSDT" 
  },
  BitGet:{
    url: 'https://bit.ly/44K8bFh',
    logo: 'https://altcoinsbox.com/wp-content/uploads/2023/04/bitget-logo.png',  
    type:"Futures",
    symbol:"BNBUSDT"  
},
    MexC:{
      url: 'https://bit.ly/45OtbeK',
      logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/mexc-logo.png',
      type:"Futures",
      symbol:"BNBUSDT"  
  },
    CoinCheck:{
        url: 'https://coincheck.com/',
        logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/coincheck-logo.jpg',  
    },
    "Crypto.com":{
        url: 'https://crypto.com/',
        logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/crypto.com-logo.png', 
        type:"Derivatives",
        symbol:"BNBUSD"  
    },
    "WazirX":{
      url: 'https://bit.ly/3DbB7KL',
      logo: 'https://wazirx.com/static/media/wazirx-logo-rounded.9bff9f42.png',
      symbol:"BNBUSDT"  
  },
    // BitCex:{
    //     url: 'https://cex.io/',
    //     logo: 'https://static-00.iconduck.com/assets.00/cex-io-icon-2048x2048-mb174xpu.png',  
    // },
    "XT.com":{
      url: 'https://www.xt.com/',
      logo: 'https://altcoinsbox.com/wp-content/uploads/2023/01/xt.com-logo.png',  
      type:"Futures",
      symbol:"BNBUSDT"
    },
    "WazirX":{
      url: 'https://wazirx.com/',
      logo: 'https://wazirx.com/static/media/wazirx-logo-rounded.9bff9f42.png',
      symbol:"BNBUSDT"  
  },
  "P2B":{
    url: 'https://p2pb2b.com/',
    logo: 'https://www.shutterstock.com/image-vector/p2pb2b-vector-logo-260nw-1254501253.jpg',  
    symbol:"BNBUSDT"
},
"BTSE":{
  url: 'https://www.btse.com/',
  logo: 'https://seeklogo.com/images/B/btse-token-btse-logo-C2B7B60285-seeklogo.com.png',  
},
    // Phemex:{
    //     url: 'https://phemex.com/',
    //     logo: 'https://img.phemex.com/wp-content/uploads/2021/09/23075908/Phemex-Logo-version-A-1024x1024.png', 
    // }
    

  };
  return (
    <div className="container">
      <h2 className="titleName">BNB</h2>
      {isMobile ? (
       <ul className="exchange-list">
       {exchangePrices.map((item) => (
         <a className='atag' href={exchangeWebsites[item.exchange].url} rel="noopener noreferrer">
         <div key={item.exchange} className="exchange-item">
             <img src={exchangeWebsites[item.exchange].logo} className="exchange-logo" />
             <div className='exchange-details'>
             <div className="exchange-name">{item.exchange}</div>     
             <div className='exchange-type'>{exchangeWebsites[item.exchange].type}</div>
             <div className='exchange-symbol'>{exchangeWebsites[item.exchange].symbol}</div>
             </div>   
             <div
               className={`price ${item.price ? '' : 'price-loading'}`}
               style={{ color: updatePriceColor(item.price, lastPrice) }}
             >
               {item.price ? `$${item.price}` : 'Loading...'}
             </div>
             {exchangePrices.length > 0 && (
               <div className="price-difference">
                 (+{calculatePriceDifferencePercentage(item.price, exchangePrices[0].price)}%)
               </div>
             )}
          
          <YourSvg></YourSvg>
           
         </div>
         </a>
       ))}
     </ul>
      ) : (
        <ul className="exchange-list">
        {exchangePrices.map((item) => (
          <a className='atag' href={exchangeWebsites[item.exchange].url} rel="noopener noreferrer">
          <div key={item.exchange} className="exchange-item">
              <img src={exchangeWebsites[item.exchange].logo} className="exchange-logo" />
              <div className="exchange-name">{item.exchange}</div>       
              <div
                className={`price ${item.price ? '' : 'price-loading'}`}
                style={{ color: updatePriceColor(item.price, lastPrice) }}
              >
                {item.price ? `$${item.price}` : 'Loading...'}
              </div>
              {exchangePrices.length > 0 && (
                <div className="price-difference">
                  (+{calculatePriceDifferencePercentage(item.price, exchangePrices[0].price)}%)
                </div>
              )}
              <div className='exchange-type'>{exchangeWebsites[item.exchange].type}</div>
              <div className='exchange-symbol'>{exchangeWebsites[item.exchange].symbol}</div>
          
                <YourSvg></YourSvg>
          </div>
          </a>
        ))}
      </ul>
      )}
    
    </div>
  
  );
  
  
};

export default Bnb;
